.card {
  max-width: 250px;
  border-radius: 5px;

  @media screen and (max-width: 1200px) {
    max-width: 400px;
  }
  @media screen and (max-width: 991px) {
    max-width: 300px;
  }
  @media screen and (max-width: 575px) {
    max-width: 100%;
    width: 600px;
  }
}

.cardHeading {
  min-height: 160px;
  @media screen and (max-width: 767px) {
    min-height: unset;
    padding: 18px 32px;
  }
}

.cardIcon {
  width: 100px;
  height: 100px;
  margin: 0 auto 8px;
}