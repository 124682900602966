.footerStyle {
  align-items: flex-start;
  justify-content: center;
  background-color: #00294D;
  padding: 32px 32px;
  text-align: left;
  height: 160px;
  @media screen and (max-width: 767px) {
    justify-content: normal;
    padding: 16px 0;
    height: 134px;
  }
  @media print {
    display: none !important;
  }
}

.footerTextStyle {
  padding: 0 32px;
  @media screen and (max-width: 767px) {
    justify-content: normal;
    padding: 0 16px;
  }
}

.footerTermOfUse {
  padding: 0 32px;
  font-size: x-large;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
}

.linkTermOfUse {
  color: white;
  font-size: 1.13636rem;
  text-decoration: none;
}

.footerButton {
  position: absolute;
  right: 20px;
  @media screen and (max-width: 375px) {
    right: 8px;
  }
}