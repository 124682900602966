.headerRoot {
  width: 100%;
  height: auto;
  background-color: #0077B3;
}

.logoWrapper {
  margin-right: 64px;
  @media screen and (max-width: 1200px) {
    margin: 32px 32px 32px 0;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.logoStyle {
  width: 170px;
  height: 170px;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
}

.headerWraper {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.headerText {
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  padding: 36px 0 0 80px;
  @media screen and (max-width: 1200px) {
    padding: 36px 0 0 36px;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 16px 64px 0;
  }
  @media screen and (max-width: 575px) {
    padding: 16px 16px 0;
  }
}
@media print and (color){
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
