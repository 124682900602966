.tooltip {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
}

.tooltipIcon {
  width: 80px;
  height: 80px;
}

.tooltip .tooltipText {
  visibility: hidden;
  width: 240px;
  background-color: #00294D;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 50;
  top: -72px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

@media print {
  .tooltip {
    display: none;
  }
}