.shareMaterialsRoot {
  width: 100%;
  border-top: 2px solid;
  @media screen and (max-width: 767px) {
    height: auto;
    border-bottom: 2px solid;
    padding-bottom: 16px;
  }
}

.shareMaterialHeader {
  padding: 24px 16px 0;
  text-align: left;
  white-space: pre-line;
  width: 110%;
  @media screen and (max-width: 767px) {
    padding-top: 24px;
    text-align: center;
    width: 100%;
  }
}

.shareMaterialsItem {
  position: relative;
  align-items: flex-end;
  margin-bottom: -10px;
  margin-top: 24px;
}

.shareMaterialsMargin {
  margin-top: 64px;
  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }
}

.iconStyle {
  width: 80px;
  height: 80px;
  @media screen and (max-width: 991px) {
    width: 70px;
    height: 70px;
  }
  @media screen and (max-width: 575px) {
    width: 65px;
    height: 65px;
  }
}

.tooltipWrapper {
  top: 25px;
  right: 25px;
  position: absolute;
}

.shareMaterialsTooltipStyle {
  position: absolute;
  top: -15px;
  right: -15px
}

.itemRow {
  position: relative;
  align-items: center;
}

.normalizedPadding {
  @media screen and (max-width: 575px) {
    padding: 0;
  }
}