.mainDataWrapper {
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.hideDataMobile {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.hideUserIcon {
  display: none;
}

.mobileDataView {
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.hideDataDesktop {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-evenly;
  }
}

.mobilePadding {
  @media screen and (max-width: 575px) {
    padding: 0;
  }
}

.printUser {
  align-items: center;
  column-gap: 8px;
}