code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "LahiTapiola Sans";
  font-weight: 400;
  font-style: normal;
  src: url('./styles/fonts/LahiTapiolaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "LahiTapiola Sans";
  font-weight: 300;
  font-style: normal;
  src: url('./styles/fonts/LahiTapiolaSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: "LahiTapiola Sans";
  font-weight: 600;
  font-style: normal;
  src: url('./styles/fonts/LahiTapiolaSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "LahiTapiola Sans";
  font-weight: 800;
  font-style: normal;
  src: url('./styles/fonts/LahiTapiolaSans-Extrabold.ttf') format('truetype');
}


