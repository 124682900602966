.percentInfo {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}

.percentInfoText {
  width: fit-content;
  margin-right: 4px;
}

.leftFinanceInfo {
  white-space: pre-line;
  border-right: 2px solid;
  align-items: center;
  padding: 0 32px;
  width: 50%;
  @media screen and (max-width: 1023px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 32px;
    border-right: none;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  @media print {
    width: 100%;
    border-right: none;
    padding-bottom: 16px;
  }
}

.centeredFinanceInfo {
  white-space: pre-line;
  width: 50%;
  margin: 24px 0;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.rightFinanceInfo {
  white-space: pre-line;
  align-items: center;
  padding: 0 32px;
  width: 50%;
  @media screen and (max-width: 1023px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 32px 32px 0 32px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 32px 0 0;
  }
  @media print {
    padding: 0;
  }
  @media print {
    width: 100%;
    border-top: 2px solid;
    padding-top: 16px;
  }
}

.noUserRightFinanceInfo {
  display: none;
}

.financeTextLeft {
  padding: 80px 0 54px;
  @media screen and (max-width: 767px) {
    padding: 64px 0 48px;
  }
  @media screen and (max-width: 575px) {
    padding: 36px 0 36px;
  }
  @media print {
    padding: 16px 0;
  }
}

.noUserFinanceText {
  padding: 24px 0;
}

.financeTextRight {
  padding: 32px 0 18px;
  white-space: pre-line;
  @media screen and (max-width: 767px) {
    padding: 32px 0 24px;
  }
  @media screen and (max-width: 575px) {
    padding: 36px 0 0;
  }
}

.moneyLeftIcon {
  width: 100px;
  height: 100px;
  margin-right: 36px;
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    margin-left: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    width: 80px;
    height: 80px;
  }
}

.moneyRightIcon {
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    margin-left: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    width: 80px;
    height: 80px;
  }
}

.oneMoneyIcon {
  width: 100px;
  height: 100px;
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
  }
  @media screen and (max-width: 575px) {
    width: 80px;
    height: 80px;
  }
}

.leftLastText {
  white-space: pre-line;
  margin-top: 16px;
  @media screen and (max-width: 767px) {
    border-bottom: 2px solid;
    padding-bottom: 16px;
  }
}

.noUserLastText {
  white-space: pre-line;
  margin-top: 16px;
}

.financeHeaderText {
  padding: 0 36px;
  white-space: pre-line;
}

.marginTop16 {
  margin-top: 16px;
}

.financeButton {
  width: 220px;
  white-space: pre-line;
  @media print {
    display: none;
  }
}

.percent {
  position: relative;
  padding: 18px 0px 18px 0;
  background-color: #EEF8FD;
  margin: 0 -430px;
  @media screen and (max-width: 767px) {
    margin: -20px -50px 0;
  }
  @media screen and (max-width: 575px) {
    padding: 16px 0 0 0;
    margin: -20px -32px 0;
  }
  @media print {
    page-break-after: always;
  }
}

.mobileFinanceView {
  display: flex;
  margin: 32px 0;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media print {
    flex-wrap: wrap;
    page-break-after: always;
  }
}

.noUserView {
  display: flex;
  justify-content: center;
}


.mobilePrint {
  display: flex;
  margin: 128px 0;
}

.financeTooltipStyle {
  position: relative;
  top: -40px;
  right: 20px;
  @media screen and (max-width: 575px) {
    top: -70px;
    right: 128px;
  }
}

.moneyAndIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.fixedHeight120 {
  height: 120px;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  @media print {
    height: auto;
  }
}
.fixedHeight180 {
  height: 180px;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  @media print {
    height: auto;
  }
}
