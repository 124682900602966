.estateErrorContactForm {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
}

.estateErrorRoot {
  background-color: #EFF8FE;
  margin: 0 -170px;
  padding: 162px 64px;
  @media screen and (min-width: 1440px) {
    margin: 0 -430px;
  }
  @media screen and (max-width: 1280px) {
    margin: 0 -100px;
    padding: 24px 64px;
  }
  @media screen and (max-width: 900px) {
    padding: 78px 64px;
  }
  @media screen and (max-width: 767px) {
    background-color: white;
    margin: 36px 0 0 0;
    padding: 0;
    width: 100%;
  }
}

.estateErrorInfoWrapper {
  margin-top: 40px;
  margin-left: -56px;
  @media screen and (min-width: 1440px) {
    margin-left: 168px;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
  @media screen and (max-width: 767px) {
    margin: 32px 0 0 0;
  }
}

.estateErrorInputGrid {
  @media screen and (max-width: 1200px) {
  margin-left: 24px;
}
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.estateErrorTitleWrapper {
  margin-bottom: 24px;
  margin-left: 20%;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0 10%;
  }
}

.estateErrorButton {
  margin-top: 32px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  @media screen and (max-width: 575px) {
    margin-top: 16px;
  }
}

.estateErrorAddInformation {
  margin: 32px;
  width: auto;
  white-space: pre-line;
}


.estateErrorImageWrapper {
  margin-right: 340px;
  @media screen and (max-width: 1439px) {
    margin-right: 140px;
  }
  @media screen and (max-width: 1280px) {
    margin-right: 80px;
  }
  @media screen and (max-width: 991px) {
    margin-right: 32px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.estateErrorBottomImage {
  padding: 32px 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    width: 110%;
    height: auto;
    padding: 0;
    margin-bottom: -24px;
  }
}
