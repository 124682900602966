
.headingH2 {
  margin: 64px auto;
  padding: 0 180px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 32px;
  }
  @media screen and (max-width: 575px) {
    margin: 24px auto;
    padding: 0;
  }
}

.headingH3 {
  width: 50%;
  margin: 48px auto 48px auto;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    padding: 0 18px;
    margin: 18px 0 18px 0;
    font-size: 12px;
  }
}

.tipsWrapper {
  justify-content: center;
  align-items: center;
  margin: 32px 25%;
  display: flex;
  @media screen and (max-width: 1200px) {
    margin: 32px 10%;
  }
  @media screen and (max-width: 767px) {
    margin: 32px 0;
  }
}

.tipsStyle {
  width: auto;
  text-align: left;
  margin-left: 16px;
}

.oneIconStyle {
  width: 80px;
  height: 80px;
  margin: 16px;
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}

.exitSectionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 0;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 28px 0;
  }
  @media screen and (max-width: 575px) {
    padding: 0;
    justify-content: center;
  }
}

.exitSectionText {
  width: 50%;
  @media screen and (max-width: 767px) {
    padding: 0 32px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.exitSectionButton {
  white-space: pre-line;
  width: 200px;
  margin: 0 32px;
  padding: 8px;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.contentWrapper {
  column-gap: 32px;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.cardItemStyle {
  height: auto;
}

