.infoCard {
  width: 310px;
  height: 440px;
  @media screen and (max-width: 767px) {
    width: 310px;
    height: 440px;
  }
  @media screen and (max-width: 575px) {
    width: 270px;
    height: 420px;
  }
}

.infoCardTextWithPadding {
  padding: 0 32px;
  line-height: 1.3em;
  font-size: 21pt;
  font-weight: 600;
  color: white;
}

.infoCardText {
  font-size: 22pt;
  line-height: 1.3em;
  font-weight: 700;
  color: white;
  height: 90px;
}

.mainCardText {
  white-space: pre-line;
  font-size: 15pt;
  font-weight: 700;
  color: white;
}

.mainCardTextWithPadding {
  padding: 32px 32px 16px 32px;
  line-height: 1.2em;
  font-size: 15pt;
  font-weight: 600;
  color: white;
}

.euroIcon {
  margin-top: 16px;
  width: 90px;
  height: 90px;
  margin-bottom: 16px;
}

.moneyValueWrapper {
  align-items: center;
  width: 100%;
  height: 80px;
}

.moneyValue {
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
}