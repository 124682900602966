.yourForestRoot {
  height: 318px;
  width: 100%;
  @media screen and (max-width: 767px) {
    border-top: 2px solid;
    height: auto;
    padding-bottom: 16px;
  }
  @media print {
    height: auto;
    @media screen and (max-width: 767px) {
      border-top: 2px solid;
      padding-bottom: 16px;
    }
  }
}

.yourForestContainer {
  text-align: start;
  margin-top: 16px;
  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }
}

.yourForestHeaderText {
  text-align: left;
  @media screen and (max-width: 767px) {
    padding-top: 24px;
    width: 100%;
    text-align: center;
  }
}

.yourForestItemStyle {
  padding-left: 0;
  height: 60px;
  @media screen and (max-width: 767px) {
    height: 50px;
  }
}