.diagramIconStyle {
  width: 60px;
  height: 60px;
  @media screen and (max-width: 575px) {
    width: 45px;
    height: 45px;
  }
}

.diagramTextStyle {
  display: flex;
  align-items: flex-end;
  margin-bottom: -8px;
}

.diagramRowItem {
  @media screen and (max-width: 575px) {
    margin-left: -8px;
  }
}


.diagramTooltipStyle {
  position: relative;
  top: 0;
  right: 8px;
}

.diagramDataWrapper {
  align-items: baseline;
  column-gap: 2px;
  @media screen and (max-width: 575px) {
    column-gap: 4px;
  }
  @media screen and (max-width: 479px) {
    column-gap: 2px;
  }
}

.diagramDotStyle {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  @media screen and (max-width: 991px) {
    width: 8px;
    height: 8px;
  }
  @media screen and (max-width: 767px) {
    width: 15px;
    height: 15px;
  }
  @media screen and (max-width: 575px) {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 479px) {
    width: 6px;
    height: 6px;
  }
}