.nextFiveHeader {
  text-align: left;
  margin-top: 24px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.nextFiveNoData {
  padding: 32px 0;
  text-align: center;
}

.NextFiveYearsDiagramRoot {
  border-top: 2px solid;
  text-align: left;
  margin-bottom: 16px;
  @media screen and (max-width: 767px) {
    border-bottom: 2px solid;
    border-top: unset;
  }
  @media screen and (max-width: 375px) {
    margin: 0 -8px;
  }
}

.nextFiveData {
  text-align: start;
  margin-top: 32px;
}

.nextFiveText {
  text-align: left;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.itemStyle {
  padding-left: 0;
  height: 60px;
}