.contactForm {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin-top: 18px;
  }
}

.hideEstateData {
  display: none;
}

.spinnerStyle {
  margin: 0 -170px;
  padding-top: 20%;
  padding-bottom: 25%;
  @media screen and (max-width: 767px) {
    padding-top: 25%;
    padding-bottom: 25%;
  }
  @media screen and (max-width: 575px) {
    padding-top: 55%;
    padding-bottom: 50%;
  }
}

.spinnerText {
  margin-bottom: 140px;
}

.estateChooseRoot {
  background-color: #EFF8FE;
  margin: 0 -170px;
  padding: 16px 0;
  @media screen and (min-width: 1440px) {
    margin: 0 -430px;
    padding: 0 0 64px;
  }
  @media screen and (max-width: 1280px) {
    margin: 0 -100px;
  }
  @media screen and (max-width: 900px) {
    margin: 0 -100px;
    padding: 116px 0 116px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 -36px;
    padding: 0;
  }
}

.estateUserText {
  width: auto;
}

.guestUserStyle {
  display: none;
}

.userDataRoot {
  display: flex;
  align-items: center;
  margin: 0 480px;
  padding-top: 148px;
  @media screen and (max-width: 1439px) {
    padding-top: 5%;
    margin: 0 184px;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 5%;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}

.userDataIcon {
  width: 45px;
  height: 45px;
  margin: 8px;
  @media screen and (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
}

.contactInput {
  margin-left: 64px;
  width: 400px;
  @media screen and (max-width: 1200px) {
    width: 320px;
  }
  @media screen and (max-width: 991px) {
    width: 280px;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
  @media screen and (max-width: 575px) {
    width: 240px;
  }
}

.inputWrapper {
  margin-top: 24px;
  margin-left: -56px;
  @media screen and (min-width: 1440px) {
    margin-left: 168px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 36px;
    margin-left: 0;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
  @media screen and (max-width: 767px) {
    margin: 32px 0 0 0;
  }
}

.inputGrid {
  @media screen and (max-width: 1200px) {
    margin-top: 16px;
    margin-left: 24px;
  }
  @media screen and (max-width: 767px) {
    margin: 16px 0 0 0;
  }
}

.inputTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 60%;
  margin-left: 25%;
  text-align: left;
  @media screen and (max-width: 991px) {
    width: 75%;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0 10%;
  }
}

.buttonWithTips {
  margin-left: 64px;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.estateContactLink {
  display: flex;
  padding: 8px 0;
}

.twoIconStyle {
  width: 80px;
  height: 80px;
  @media screen and (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}

.inputTitle {
  width: auto;
  margin-left: 16px;
}

.infoAboutMask {
  width: auto;
  margin-left: 64px;
  margin-top: 8px;
  padding:0 80px;
  white-space: pre-line;
  @media screen and (max-width: 921px) {
    font-size: 12px;
    padding:0 24px;
  }
  @media screen and (max-width: 767px) {
    margin: 32px 0 0 0;
    font-size: 12px;
  }
}

.imageWrapper {
  margin-right: 340px;
  @media screen and (max-width: 1439px) {
    margin-right: 140px;
  }
  @media screen and (max-width: 1280px) {
    margin-right: 140px;
  }
  @media screen and (max-width: 991px) {
    margin-right: 96px;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.bottomImage {
  padding: 32px 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    width: 110%;
    height: auto;
    padding: 0;
    margin-bottom: -24px;
  }
}
