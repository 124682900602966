.estateNotFoundContactForm {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin-top: 0;
  }
}

.estateNotFoundRoot {
  background-color: #EFF8FE;
  padding: 32px 64px;
  margin: 0 -160px;
  @media screen and (min-width: 1440px) {
    margin: 0 -430px;
  }
  @media screen and (max-width: 1280px) {
    padding: 0 64px;
    margin: 0 -160px;
  }
  @media screen and (max-width: 767px) {
    background-color: white;
    margin: 0;
    padding: 32px 16px;
  }
  @media screen and (max-width: 575px) {
    background-color: white;
    margin: auto;
    padding: 16px 0;
  }
}

.estateNotFoundContactInput {
  width: 400px;
  @media screen and (max-width: 1200px) {
    width: 280px;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
  @media screen and (max-width: 575px) {
    width: 240px;
  }
}

.estateNotFoundInputWrapper {
  @media screen and (min-width: 1440px) {
    margin-left: 168px;
  }
  @media screen and (max-width: 1280px) {
    padding: 64px 0;
  }
  @media screen and (max-width: 991px) {
    padding: 32px 0 0 32px;
    margin-left: 32px;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.estateNotFoundInputTitleWrapper {
  margin-bottom: 24px;
  text-align: center;
  @media screen and (max-width: 991px) {
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 5%;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0;
  }
}

.estateNotFoundContactLink {
  width: auto;
  margin: 16px 0;
  @media screen and (max-width: 575px) {
    margin: 0;
}
}

.estateNotFoundButtonWithTips {
  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 0;
  }
}

.estateNotFoundInputTitle {
  width: auto;
  padding: 0 64px;
}

.estateNotFoundInputGrid {
  margin: 16px 0 0 0;
  @media screen and (max-width: 991px) {
    margin-left: 32px;
  }
  @media screen and (max-width: 767px) {
    margin: 16px 0 0 0;
  }

}

.estateNotFoundInfoAboutMask {
  width: auto;
  padding:0 64px;
  white-space: pre-line;
  @media screen and (max-width: 1200px) {
    padding:0 32px;
  }
  @media screen and (max-width: 921px) {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    margin: 16px 0;
    padding: 0 8px;
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.estateNotFoundImageWrapper {
  margin-right: 340px;
  @media screen and (max-width: 1439px) {
    margin-right: 32px;
  }
  @media screen and (max-width: 1280px) {
    margin-right: 64px;
  }
  @media screen and (max-width: 991px) {
    margin-right: 96px;
  }
  @media screen and (max-width: 767px) {
    display: none;
    margin: 0;
  }
}

.estateNotFoundBottomImage {
  padding: 32px 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    width: 110%;
    height: auto;
    padding: 0;
    margin-bottom: -24px;
  }
}

.estateNotFoundSpinnerStyle {
  margin: 0 -170px;
  padding-top: 20%;
  padding-bottom: 25%;
  @media screen and (max-width: 767px) {
    padding-top: 25%;
    padding-bottom: 25%;
  }
  @media screen and (max-width: 575px) {
    padding-top: 55%;
    padding-bottom: 50%;
  }
}

.estateNotFoundSpinnerText {
  margin-bottom: 140px;
}
