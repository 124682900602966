.diagramRoot {
  width: 100%;
  height: 320px;
  @media screen and (max-width: 767px) {
    border-top: 2px solid;
    padding-top: 24px;
    height: auto;
  }
  @media print {
    height: 340px;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
}

.diagramContainer {
  display: flex;
  margin-top: 32px;
  align-items: flex-end;
  justify-content: space-around;
  @media screen and (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media print {
    margin-right: 16px;
    width: 100%;
    margin-top: 64px;
    margin-bottom: 8px;
  }
}

.firstTypeOfTree {
  align-items: center;
  padding: 0 8px;
}

.twoTypeOfTree {
  align-items: center;
  padding: 0 8px;
}

.threeTypeOfTree {
  align-items: center;
  padding: 0 8px;
}

.diagramHeader {
  text-align: left;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.bigTree {
  width: 115px;
  height: 150px;
  @media screen and (max-width: 375px) {
    width: 100px;
    height: 100px;
  }
  @media print {
    width: 90px;
    height: 90px;
  }
}

.smallTree {
  width: 100px;
  height: 100px;
  @media screen and (max-width: 375px) {
    width: 50px;
    height: 50px;
  }
  @media print {
    width: 45px;
    height: 45px;
  }
}
