.threeIconStyle {
  width: 60px;
  height: 60px;
  margin: 16px;
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}

.tipsResultWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
  @media print {
    display: none;
  }
}

.tipsResultStyle {
  width: auto;
  text-align: left;
  margin-left: 16px;
}

.mainAdress {
  text-align: start;
  margin: 24px auto;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.addressWithIcon {
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.locationPrint {
  @media print {
    display: none;
  }
}

.map {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 500px;
  @media screen and (max-width: 1279px) {
    height: 400px;
  }
  @media print {
    page-break-after: always;
  }
}


.infoCardGrid {
  margin-top: 16px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.infoCardMargin {
  margin-right: 112px;
  margin-left: 12px;
  @media screen and (max-width: 767px) {
    margin: 0 50%;
    padding: 0;
  }
  @media print {
    margin-right: 64px;
  }
}

.mobileCentering {
  padding: 0;
  @media screen and (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
  @media print {
    margin-top: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  padding-top: 16px;
  @media print {
    display: none;
  }
}

.bottomButtonMapSecondary {
  margin-right: 16px;
}

.circle {
  width: 175px;
  height: 175px;
  line-height: 165px;
  background: #00294D;
  margin: 64px 0 32px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: 700;
  @media screen and (max-width: 575px) {
    width: 100px;
    height: 100px;
    line-height: 95px;
    font-size: 35px;
  }
}

.center {
  margin-top: -36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftDiagramContainer {
  border-right: 2px solid;
  align-items: center;
  padding-left: 16px;
  padding-right: 32px;
  width: 50%;
  @media screen and (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.rightDiagramContainer {
  padding-left: 32px;
  padding-right: 16px;
  width: 50%;
  @media screen and (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mobileDiagramContainer {
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 575px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.separatorStyle {
  background-color: #00294D;
  margin: 20px -415px;
  padding: 32px 415px;
  text-align: left;
  @media screen and (min-width: 1440px) {
    margin: 20px -430px;
    padding: 32px 430px;
  }
  @media screen and (max-width: 1280px) {
    margin: 20px -100px;
    padding: 32px 100px;
  }
  @media screen and (max-width: 767px) {
    margin: 20px -36px;
    padding: 32px 36px;
  }
  @media print {
    display: none;
  }
}

.termOfUseLink {
  cursor: pointer;
}

.printButton {
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  @media print {
    display: none;
  }
}

.printButtonSize {
  width: 320px;
  padding: 8px;
  white-space: pre-line;
}

.infoBlockWithButtons {
  padding: 35px 170px;
  background-color: #EFF8FE;
  margin: 24px -170px 0;
  @media screen and (min-width: 1440px) {
    margin: 24px -430px 0;
    padding: 32px 430px;
  }
  @media screen and (max-width: 1280px) {
    margin: 24px -100px 0;
    padding: 32px 100px;
  }
  @media screen and (max-width: 767px) {
    margin: 24px -36px 0;
    padding: 32px 36px;
  }
  @media print {
    display: none;
  }
}

.joinUsWrapper {
  display: flex;
  margin-top: 64px;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 767px) {
    margin-top: 32px;
    flex-wrap: wrap;
  }
}

.joinUsTextWithButtons {
  display: flex;
  align-items: center;
  column-gap: 64px;
  @media screen and (max-width: 991px) {
    column-gap: 16px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-around;
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    width: 100%;
  }
}

.joinUsText {
  width: 280px;
  margin-bottom: 32px;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.joinUsButtons {
  width: 240px;
  white-space: pre-wrap;
  align-items: center;
  @media screen and (max-width: 799px) {
    width: 220px;
  }
}

img {
  width: 40px;
  height: 40px;
  @media screen and (max-width: 575px) {
    width: 25px;
    height: 25px;
  }
}

.smallButtonText {
  font-size: 12px;
}

.mt24 {
  display: flex;
  margin-top: 24px;
  @media screen and (max-width: 575px) {
    width: auto;
  }
}

.footerTextWrapper {
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding: 0 32px;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
}

